<template>
  <div id="home">
    <div class="scroll-label">
      Scroll
      <div class="label-line-wrap">
        <div class="label-line"></div>
      </div>
    </div>
    <div class="title-wrapper">
      <p class="home-title">Welcome</p>
      <p class="home-subtitle">Your future without plastic</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home-section',
}
</script>

<style lang="sass" scoped>
@use "~@base" as *

#home
  //min-height: 100%
  min-height: 90rem
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url('~@/assets/images/product-overview.webp')
  background-size: cover

  .title-wrapper
    margin-left: 10rem
    display: flex
    flex-direction: column
    align-items: center
    color: $clr-white

    .home-title
      font-size: 9rem
      border-bottom: 0.2rem solid $clr-white
      margin-bottom: 0.8rem

    .home-subtitle
      font-size: 2rem
      font-weight: 400

  .scroll-label
    position: absolute
    bottom: 2rem
    right: 10rem
    writing-mode: vertical-rl
    transform: rotate(180deg)
    text-transform: uppercase
    font-weight: bold
    font-size: 1.4rem
    color: $clr-white
    letter-spacing: 0.5rem
    padding-bottom: 7rem

    .label-line-wrap
      display: block
      width: 0.2rem
      height: 5.8rem
      margin: 0 auto
      position: absolute
      bottom: 0
      right: 0
      left: 0

      .label-line
        position: absolute
        top: 0
        width: 100%
        height: 0
        background: $clr-white
        animation: 2s scrollLabelLineAnimation ease-in infinite forwards

      +keyframes(scrollLabelLineAnimation)
        0%
          height: 0
          top: 0
        49%
          height: 5.8rem
          top: 0
        50%
          height: 5.8rem
        51%
          bottom: 0
          top: inherit
          height: 5.8rem
        99%
          top: inherit
          height: 0
          bottom: 0
        100%
          height: 0
          bottom: inherit
          top: 0
</style>