import 'vue-fullpage.js/dist/style.css'
import '../style/main.sass'
// import VueFullPage from 'vue-fullpage.js'
import vClickOutside from 'click-outside-vue3'

import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App)
// app.use(VueFullPage)
app.use(vClickOutside)
// app.config.productionTip = false

app.mount('#app')
