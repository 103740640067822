<template>
  <div id="about-us" class="section">
    <div class="main">
      <span class="icon-logo"></span>
      <p class="section-title">about us</p>
      <div>
        <b>Kraftbags</b> is a company that manufactures and sells kraft paper bags made from
        various types of paper. To improve the environmental situation, more than 40 countries
        have restricted or banned the use of single-use plastic bags. We have decided not to be
        left behind, so we are in the business of producing environmentally friendly paper
        packaging for food and non-food products every day. Our mission is to provide
        affordable, environmentally friendly and convenient packaging for catering
        organisations, fast food restaurants and cafes, bakeries and confectioners, retail chains
        and supermarkets
      </div>
      <div class="ending-text">
        <p>Call and order kraft bags wholesale and retail and organise your future without plastic</p>

        <p>
          <b>We believe in long term and mutually beneficial relationships!</b>
        </p>
      </div>
    </div>
    <div class="about-us-details">
      <div class="block">
        <p class="number">01</p>
        <h3 class="title">Logo kraft bags</h3>
        <div class="text">
          Logo bags are not only a beautiful and aesthetic packaging, but also the most important
          advertising tool. With the help of such a simple product you can tell about yourself, the
          product you sell or the service you provide to the maximum number of interested
          consumers. With us you can profitably order paper bags with your logo and related
          elements of your corporate identity. You can order bags with your logo: bags with
          handles, bags with rectangular bottom, bags with paper corners, bags with flat bottom
        </div>
      </div>
      <div class="block">
        <p class="number">02</p>
        <h3 class="title">Printed wrapping paper</h3>
        <div class="text">
          Our company produces high quality printing on kraft paper, wrapping paper, gift wrap,
          packaging paper, which is increasingly in demand by owners of coffee shops,
          restaurants, snack bars, fashion boutiques and supermarkets. Branded wrapping paper
          is stylish and original packaging that makes your products unique and memorable.
          Paper wrapped goods are always in high demand by customers, who not only care
          about quality, but also about the outward appearance of products
        </div>
      </div>
      <div class="block">
        <p class="number">03</p>
        <h3 class="title">Why do customers choose us?</h3>
        <div class="text">
          We have our own production, which means you don't have to overpay intermediaries.
          Our printing technology is designed for printing on paper bags, which allows us to print
          the highest quality images. We use environmentally friendly ink that has no
          harmful fumes and no odour. It is approved for food packaging. We fulfil your order promptly.
          Qualified, experienced professionals. We always go to the customer and it is possible to agree with us.
        </div>
      </div>
      <div class="block-wrapper">
        <div class="block">
          <p class="number">04</p>
          <h3 class="title">Here you can buy kraft bags:</h3>
          <div class="text">
            <ul>
              <li>for tea/coffee, for a bottle</li>
              <li>for grilled chicken, for bread and baked goods</li>
              <li>for flour and bulk products, for takeaway food</li>
              <li>for industrial products, for burgers and hot dogs</li>
              <li>for the fast-food segment, food delivery</li>
            </ul>
          </div>
        </div>
        <div class="block">
          <p class="number">05</p>
          <h3 class="title">When you work with us, you can count on</h3>
          <div class="text">
            <ul>
              <li>Reasonable prices</li>
              <li>Quality products</li>
              <li>Convenient service</li>
              <li>Discounts based on order volume</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'about-us',
  setup() {},
}
</script>

<style lang="sass" scoped>
@use "~@base" as *

#about-us
  padding: 4rem 10rem
  flex-direction: row
  gap: 5rem

  .main
    display: flex
    flex-direction: column
    align-items: center
    text-align: center
    gap: 2rem
    max-width: 70rem

    .icon-logo
      font-size: 5.5rem

    .ending-text
      margin-top: 5rem

  .about-us-details
    display: flex
    flex-direction: column
    gap: 2rem
    flex: 1

    .block-wrapper
      display: flex
      justify-content: space-between
      gap: 1rem

      .block
        flex: 1 1 0

    .block
      display: flex
      flex-direction: column
      gap: 1rem

      .number
        color: $clr-primary
        font-weight: 700
        font-size: 1.4rem

      .title
        font-size: 1.8rem
        font-weight: 500
        color: $clr-primary

      .text
        color: #0000004D
        line-height: 2rem
        font-weight: 300
</style>