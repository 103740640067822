<template>
  <div id="work-in-process">
    <iframe src="https://www.youtube.com/embed/JX8y6W0Kk-Y"
            title="YouTube video player"
            allow="accelerometer autoplay clipboard-write encrypted-media gyroscope picture-in-picture web-share"
            allowfullscreen></iframe>
    <p class="section-title vertical">work in process</p>
  </div>
</template>

<script>
export default {
  name: 'video-section',
}
</script>

<style lang="sass" scoped>
@use "~@base" as *

#work-in-process
  flex-direction: row
  padding: 8rem 8rem
  gap: 5rem

  .section-title
    transform: rotate(360deg)
    font-size: 5rem

  iframe
    width: 75%
    height: 60rem
    box-shadow: $box-shadow-1
    border-radius: 0.4rem

</style>