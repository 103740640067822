<template>
  <div id="gallery">
    <div class="gallery-panel"
         v-for="photo in PHOTOS"
         :key="photo.id">
        <img :src="thumbUrl(photo.filename)" />
    </div>
  </div>
</template>

<script>
import PHOTOS from '@/PHOTOS'

export default {
  name: 'gallery-section',
  setup() {
    const thumbUrl = (filename) => {
      return require(`../assets/images/${filename}`)
    }

    return {
      PHOTOS,
      thumbUrl,
    }
  }
}
</script>

<style lang="sass" scoped>
@use "~@base" as *
#gallery
  display: flex
  align-items: center
  flex-direction: row
  gap: 5rem
  padding: 8rem 8rem
  background-image: url('../assets/images/pattern.png')

  .gallery-panel
    width: 20%


  .gallery-panel img
    width: 100%
    height: 22vw
    object-fit: cover
    border-radius: 0.75rem
</style>