<template>
  <div id="products">
    <div class="image-grid">
      <img class="image-grid-col-2" src="../assets/images/products/hashtag_sport.webp"/>
      <img src="../assets/images/products/keto.webp"/>
      <img src="../assets/images/products/koriz.webp"/>
      <img src="../assets/images/products/avocado-queen.webp"/>
      <img src="../assets/images/products/proshyan.webp"/>
      <img src="../assets/images/products/12ktor.webp"/>
      <img src="../assets/images/products/sportlandia.webp"/>
      <img src="../assets/images/products/quiz.webp"/>
      <img src="../assets/images/products/nkoogh.webp"/>
      <img class="image-grid-col-2" src="../assets/images/products/fryday.webp"/>
      <img src="../assets/images/products/tabby.webp"/>
      <img src="../assets/images/products/hihub.webp"/>
      <img src="../assets/images/products/bakery.webp"/>
      <img class="image-grid-col-2" src="../assets/images/products/white.webp"/>
      <img class="image-grid-col-2" src="../assets/images/products/bolsero.webp"/>
      <img src="../assets/images/products/domany.webp"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'products-section',
}
</script>

<style lang="sass" scoped>
@use "~@base" as *

#products
  padding: 6rem 2rem
  background-image: url('../assets/images/pattern.png')

.image-grid
  --gap: 1rem
  --num-cols: 5

  width: 100%
  height: 100%
  display: grid
  grid-template-columns: repeat(var(--num-cols), 1fr)
  grid-auto-rows: 1fr
  gap: var(--gap)
  box-sizing: border-box

  img
    position: relative
    top: 0
    width: 100%
    height: 100%
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1)
    transition: all .1s ease-in

    &:hover
      top: -2px
      box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2)


  .image-grid-col-2
    grid-column: span 2
    grid-row: span 1

</style>