<template>
  <div :class="{ 'dark' : isDark }">
    <div>
      <HomeSection class="section"/>
      <Gallery class="section"/>
      <AboutUs class="section"/>
      <Products class="section"/>
      <VideoSection class="section"/>
      <ContactUs class="section"/>

    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import HomeSection from '@/components/home'
import AboutUs from '@/components/about'
import Products from '@/components/products'
import VideoSection from '@/components/video'
import ContactUs from '@/components/contact-us'
import Gallery from '@/components/gallery'

export default {
  name: 'App',
  components: { AboutUs, HomeSection, Products, VideoSection, Gallery, ContactUs },
  setup() {
    const isDark = ref(false)

    return {
      isDark,
    }
  }
}
</script>

<style>
</style>
