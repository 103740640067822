<template>
  <div id="contact-us" class="section">
    <div id="contacts-wrapper">
      <div class="contact-details-wrapper">
        <h3 class="title">Contacts</h3>
        <ul class="contact-details">
          <li>
            <p class="label">Address:</p>
            <p class="text">104/17 Vakhtang Ananayan street, Yerevan 0065, Armenia</p>
          </li>
          <li>
            <p class="label">Phone:</p>
            <a class="phone" href="tel:+37491056066">+374 91 056 066</a>
            <a class="phone" href="tel:+37410743922">+374 10 743 922</a>
          </li>
          <li class="socials">
            <div>
              <p class="label">Email:</p>
              <p class="text">info@kraftbags.am</p>
            </div>
            <div>
              <a class="social-link" href="https://www.facebook.com/people/Kraftbags/61553188647111/" target="_blank">
                <span class="social-icon icon-fb"></span>
              </a>
              <a class="social-link" href="https://www.instagram.com/kraftbags.evn/" target="_blank">
                <span class="social-icon icon-insta"></span>
              </a>
            </div>
          </li>
        </ul>
      </div>
      <GoogleMap
        id="map"
        api-key="AIzaSyCK_Ii1yqALfHrHsfeiF7WqYIuG0rkr75Y"
        :center="center"
        :zoom="15"
        :disable-default-ui="true"
        :zoom-control="true"
        :scrollwheel="false"
        gesture-handling="greedy"
        :styles="styles"
      >
        <CustomMarker :options="{ position: center, anchorPoint: 'BOTTOM_CENTER' }">
          <img src="../assets/images/marker-small.png"/>
        </CustomMarker>
      </GoogleMap>
    </div>
    <p class="copyright">
      &#169; 2019 Kraftbags, Inc. All Rights Reserved.
    </p>
  </div>
</template>

<script>
import GOOGLE_MAPS_STYLES from '@/GOOGLE_MAPS_STYLES'
import { GoogleMap, CustomMarker } from 'vue3-google-map'

export default {
  name: 'contact-us',
  components: { GoogleMap, CustomMarker },
  setup() {
    const center = { lat: 40.187841, lng: 44.449909 }
    return {
      center,
      styles: GOOGLE_MAPS_STYLES,
    }
  }
}

</script>

<style lang="sass" scoped>
@use "~@base" as *

#contact-us
  height: 70rem
  position: relative
  background-color: #F2F4F7

  #contacts-wrapper
    position: relative
    display: flex
    height: 70%
    justify-content: center
    align-items: center


    .contact-details-wrapper
      position: absolute
      top: 50%
      right: 50%
      width: 50rem
      min-height: 30rem
      background-color: #fff
      z-index: 1
      border-radius: 0.2rem
      margin: -15rem 15rem 0 0
      box-shadow: 0 0.2rem 0.1rem -0.1rem rgba(0,0,0,0.2), 0 0.1rem 0.1rem 0 rgba(0,0,0,0.14), 0 0.1rem 0.3rem 0 rgba(0,0,0,0.12)
      padding-left: 3rem
      padding-right: 3rem
      display: flex
      flex-direction: column
      justify-content: center


      h3
        font-size: 4rem
        color: #343B4B
        padding: 0
        margin: 0


      .contact-details
        list-style: none
        margin: 2rem 0 0 0
        padding: 0
        word-break: break-word

        li
          margin: 1.5rem 0
          font-size: 1.5rem
          font-weight: 400

          &.socials
            display: flex
            align-items: flex-end
            justify-content: space-between

          .label
            color: #0000004D

          .phone
            display: block
            text-decoration: none
            color: $clr-primary
            line-height: 2rem

            &:hover
              text-decoration: underline

          .social-link
            color: $clr-primary
            font-size: 3rem
            transition: opacity .2s ease-in-out
            text-decoration: none

            &:hover
              opacity: 0.8


          .text
            color: $clr-primary


    #map
      width: 1000px
      height: 100%
      box-shadow: 0 0.3rem 0.3rem -0.2rem rgba(0,0,0,0.2), 0 0.3rem 0.4rem 0 rgba(0,0,0,0.14), 0 0.1rem 0.8rem 0 rgba(0,0,0,0.12)


  .copyright
    position: absolute
    bottom: 1.5rem
    font-size: 1.3rem
    font-weight: 500

</style>